@import "../../styles/Variables";

.day-box {
  &__container {
    position: relative;
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__item {
    border: 1px solid $secondary-main;
    border-radius: 4px;
    color: $secondary-main;
    padding: 17px 20px;
    margin-right: 10px;
    text-align: center;
    cursor: pointer;

    &--active {
      background-color: $secondary-main;
      color: #fff;
    }
  }
  &__day {
  }
  &__date {
    width: max-content;
  }
}

@media (min-width: 1025px) {
  .day-box {
    &__container {
      justify-content: center;
      margin: 30px 0;
    }
  }
}
