.maps-root {
  height: 100vh;
  // min-height: -webkit-fill-available;
}

@media (min-width: 1025px) {
  .maps-root {
    height: 100vh;
    // min-height: -webkit-fill-available;
  }
}
