@import "../../styles/Variables";

.a-autocomplete {
  display: flex;
  align-items: flex-start;
  &__container {
    width: 100%;
  }
  &__error {
    padding: 10px 0 0 10px;
  }
  &__icon {
    background-color: $secondary-main;
    height: 56px;
    display: flex;
    align-items: center;
    color: white;
    padding: 20px 9px;
    border-radius: 4px;
    margin-left: 5px;
    border: none;
    transition: background-color 200ms ease;
    &--disabled {
      background-color: grey;
      path {
        fill: #ffffff !important;
      }
    }
  }

  path {
    fill: #3c1504;
  }
}
