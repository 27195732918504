@import "../../styles/Variables";

.pu-root {
  width: 100%;
  position: relative;
  .pu-box-container {
    position: relative;
    bottom: 250px;
    padding-left: 5px;
    overflow-y: auto;
    transition: 0.2s opacity ease;
  }
  .pu-box-subcontainer {
    margin: 10px 0;
    display: flex;
  }
  .pu-root-map {
  }
}

@media (min-width: 1025px) {
  .pu-root {
    display: flex;
    flex-direction: row-reverse;
    .pu-box-container {
      position: initial;
      min-width: 380px;
      background: $background-pink;
      padding-left: 0;
    }
    .pu-box-subcontainer {
      flex-direction: column;
      margin: 0;
      //
    }
  }
}
