.maps-header {
  padding: 6px 10px 4px 10px;
  border-bottom: 1px solid white;
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 15px;
  z-index: 10;
  position: relative;
  &__logo {
    display: none;
  }
  &__toggle {
    margin-top: 12px;
  }

  &__address-container {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  &__address {
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__icon {
    color: grey;
  }
  &__divider {
    margin: 2px 0 0;
    border-bottom: 1px solid grey;
  }
}

@media (min-width: 1025px) {
  .maps-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__logo {
      display: block;
      cursor: pointer;
    }
    &__address-container {
      order: 2;
      min-width: 400px;
      border-bottom: 1px solid;
    }
    &__toggle {
      min-width: 480px;
      margin-top: 0;
    }
  }
}
