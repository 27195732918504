.pu-box {
  position: relative;
  background-color: white;
  display: flex;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 80%;
  max-width: 500px;
  height: fit-content;

  &__shipping_method {
    position: absolute;
    top: -25px;
    left: 0;
    display: flex;
    column-gap: 4px;
    align-items: center;
    background-color: #fed40f;
    padding: 4px 8px;
    border-radius: 8px 8px 0px 0px;

    p {
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      color: #272100;
    }
  }

  &__info {
    // max-width: 200px;
    padding: 6px;
    width: 100%;
    min-height: 145px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button {
      align-self: flex-start;
    }
  }
  &__header {
    // max-width: 200px;
    text-transform: capitalize;
  }

  &__distance {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    text-transform: lowercase;
    margin: 0;
  }

  &__name {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: $text-primary;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
    white-space: nowrap;
    margin: 0;
  }

  &__address {
    font-size: 14px;
    line-height: 16px;
    color: $text-secondary;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    width: 200px;
    white-space: nowrap;
  }
  &__footer {
    line-height: 16px;
    margin: 0px;
  }
  &__footer-title {
    font-size: 14px;
    color: $text-secondary;
    margin: 0px;
    // margin-top: 10px;
  }
  &__time-slots {
    font-size: 16px;
    color: $text-primary;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  &__id {
    padding: 0 13px;
    background-color: $primary-dark;
    display: flex;
    align-items: center;
    color: white;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

@media (min-width: 1025px) {
  .pu-box {
    margin-right: 0;
    margin-top: 12px;
    max-width: 350px;
    &:last-child {
      margin-bottom: 20px;
    }
    &__info {
      max-width: 300px;
      padding: 10px 20px;
    }
    &__header {
    }
    &__name {
    }

    &__address {
      margin: 0px;
    }
    &__footer {
    }
    &__footer-title {
      margin: 0px;
    }
    &__time-slots {
      margin: 6px 0;
    }
    &__id {
      min-width: 50px;
      justify-content: center;
    }
  }
}
