@import "../../styles/Variables";

.ld {
  padding: 20px 10px;
  height: 100vh;
  &__header {
    font-size: 25px;
    letter-spacing: -0.5px;
  }
  &__date-selection {
    margin-top: 10px;
  }

  &__submit-btn {
  }
}

@media (min-width: 1025px) {
  .ld {
    text-align: center;
    margin-top: 50px;
    &__submit-btn {
      max-width: fit-content;
      margin: auto;
    }
  }
}
