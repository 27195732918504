#submitButton {
  .submit-btn {
    background-color: $secondary-main;
    opacity: 1 !important;
    color: #3c1504;
    border-radius: 4px;
    width: 100%;
    margin-top: 15px;
    bottom: 0;
    position: relative;
    padding: 17px 20px;
    transition: background-color 200ms ease;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    &--disabled {
      background-color: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.26);
    }
  }

  @media (min-width: 1025px) {
    .submit-btn {
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 30px;
    }
  }
}
