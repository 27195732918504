.autocomplete-dropdown-container {
  .suggestion-item {
    background-color: #ffffff;
    padding: 10px 5px;
    cursor: pointer;
    color: $primary-dark;
    font-weight: 600;
    display: flex;
    &--active {
      cursor: pointer;
      background-color: $action-active;
    }
    &__text {
      display: flex;
      flex-direction: column;
      padding-left: 8px;
      .main {
      }
      .secondary {
        font-size: 12px;
        color: grey;
        padding-top: 4px;
      }
    }
  }
}
