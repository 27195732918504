@import "../../styles/Variables";

.toggle-method {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__btn {
    background-color: white;
    border: none;
    border-radius: 15px;
    padding: 4px 5px;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: grey;
    flex: 1;
    justify-content: center;
    font-weight: 500;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &--active {
      background-color: $primary-main;
      color: #fff;
    }
  }

  .method-icon {
    padding-right: 6px;
    padding-top: 6px;
  }
  .method-header {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 1025px) {
  .toggle-method {
    padding: 0 10px;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .toggle-method {
    &__btn {
      font-size: 12px;
    }
  }
}
