.pu-overview {
  color: $primary-dark;

  &__header {
    display: flex;
    background: $background-pink;
    padding: 20px 40px;
    align-items: center;
    &__text {
      line-height: 130%;
      padding-left: 10px;
    }
    &__icon {
      cursor: pointer;
    }
  }
  &__main {
    padding: 20px 20px 0;
  }
  &__name {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  &__address {
    font-size: 14px;
    margin-bottom: 35px;
    text-transform: capitalize;
  }
  &__title {
    font-weight: bold;
    font-size: 22px;
    letter-spacing: -0.5px;
  }
  &__slots {
    padding-left: 15px;
  }
}
