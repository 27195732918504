@import "../../styles/Variables";
.any-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: $primary-dark;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  color: white;
  transition: background-color 200ms ease, opacity ease-in-out 200ms;
  font-size: 16px;

  opacity: 0;
}
// .any-pin:after {
//   content: "";
//   width: 14px;
//   height: 14px;
//   margin: 8px 0 0 8px;
//   //   background: #e6e6e6;
//   position: absolute;
//   border-radius: 50%;
// }

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  background: #2196f3;
  border-radius: 50%;
  border: 3px solid white;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  //   transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #2196f3;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
