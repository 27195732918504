.partner {
  padding: 20px 10px;
  font-size: 16px;
  &__header {
    font-size: 25px;
    letter-spacing: -0.5px;
    margin-bottom: 15px;
    line-height: 33px;
  }
  .item {
    cursor: pointer;
  }
  .name {
    margin-bottom: 3px;

    &--active {
      color: $secondary-main;
      font-weight: bolder;
    }
  }
  .address {
    font-size: 14px;
    color: grey;
    margin-bottom: 5px;
  }
  .divider {
    margin: 0;
  }
  &__pickups {
    margin-top: 25px;
  }
  .loader {
    text-align: center;
    margin: 10px 0;
  }
}

@media (min-width: 1025px) {
  .partner {
    max-width: 700px;
    margin: 20px auto 0;
  }
}
