@import "../../styles/Variables";

.address-selection {
  padding: 20px;
  &__header {
    color: $primary-dark;
    font-size: 25px;
    letter-spacing: -0.5px;
    &--mobile {
    }
    &--desktop {
      display: none;
    }
  }

  &__subtitle {
    display: none;
  }
  &__partner-text {
    padding-right: 10px;
    // font-size: 12px;
  }
}

// Desktop
@media (min-width: 768px) {
  .address-selection {
    padding: 0;

    max-width: 880px;
    margin: 110px auto 0;
    &__header {
      text-align: center;
      &--mobile {
        display: none;
      }
      &--desktop {
        display: block;
        margin-bottom: 10px;
      }
    }
    &__subtitle {
      display: block;
      text-align: center;
      color: $primary-dark;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 120%;
      margin-bottom: 30px;
    }
    &__partner-text {
      // font-size: 14px;
    }
  }
}
